@import "@splidejs/splide/dist/css/splide.min.css";
@import "./assets/fonts/stylesheet.css";

:root {
  font-family: "CircularXXWeb-Regular", Inter, system-ui, Avenir, Helvetica,
    Arial, sans-serif;
  line-height: 1;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #fff;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#image-slider .splide__arrow {
  font-size: 20px;
  line-height: 1;
  background-color: #cecece;
  border-radius: 50%;
  padding: 10px;
}

#image-slider .splide__arrow::before {
  font-size: 20px;
}

#image-slider .splide__arrow:hover {
  background-color: #cecece;
}

#image-slider .splide__arrow svg {
  fill: #302353;
  height: 1.2em;
  width: 1.2em;
}

#image-slider .splide__pagination__page {
  display: none;
}

#image-slider .splide__pagination__page.is-active {
  background-color: #302353;
}

.splide__pagination {
  bottom: 0 !important;
}

body {
  margin: 0;
  box-sizing: border-box;
}

code {
  font-family: "CircularXXWeb-Regular", sans-serif;
}
