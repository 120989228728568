/*
 *
 * INSTRUCTIONS
 *
 * Copy the Legal Disclaimer and the @font-faces statements to your regular CSS file.
 * The font file(s) should be placed relative to the regular CSS file.
 *
 */

/*
 * Legal Disclaimer for Web licence
 *
 * Licence to use self-hosted webfonts for displaying dynamic text on specified website domains. Our package includes WOFF and WOFF2 font formats.
 *
 * Should the license page views limit be exceeded the license holder will be required to contact Lineto.com for an upgrade.
 * 
 * It is strictly prohibited to rename the font and to download or use these fonts in any other media. 
 * 
 * These Web fonts are licensed exclusively for the use on the following domain(s) and their subdomains:
 *
 * xplainfinancial.com (monthly pageviews: <50K)
 *
 */

/* complete */

@font-face {
  font-family: "CircularXXWeb-Bold";
  src: url("CircularXXWeb-Bold.woff") format("woff");
}

@font-face {
  font-family: "CircularXXWeb-Regular";
  src: url("CircularXXWeb-Regular.woff") format("woff");
}

@font-face {
  font-family: "CircularXXWeb-Medium";
  src: url("CircularXXWeb-Medium.woff") format("woff");
}

@font-face {
  font-family: "CircularXXWeb-Light";
  src: url("CircularXXWeb-Light.woff") format("woff");
}
